import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./terms.css"
const TermsCondition = () => {
    return (
        <Layout>
            <Seo title="Terms & Conditions" />
            <div className="terms__wrapper">
                <h3>Terms & Conditions</h3>
                <p>
                    Date of last update:{" "}
                    <span className="bold">2023-04-20</span>
                </p>
                <p>
                    Welcome to ARAY Society! Below we have outlined our Terms of
                    Use. Please read them carefully before using our app or our
                    website. Acceptance of these Terms of Use constitutes a
                    binding contract between you and ARAY Society. If you have
                    any questions after reading, please contact our support at
                    info@araysocity.com and we’ll be happy to help.
                </p>
                <p>
                    Please read the following terms of use carefully before
                    using any sites or Services provided by ARAY Society AB
                    (hereafter “ARAY”).
                </p>
                <ol>
                    <li>
                        <span className="point-title bold">
                            Introduction and description of the Service
                        </span>
                        <p>
                            These Terms of Use relate to the use of our website
                            and our mobile app (“Services”), which is owned and
                            operated by ARAY Society AB, Tyska Skolgränd 4,
                            11131 Stockholm, Sweden. The Service is providing
                            the user with a public website and a female golf
                            community in the form of a mobile application which
                            gives the golfers a new way to connect with each
                            other, get inspired, take part in trips,
                            tournaments, book tee times, take part in offers and
                            much more. The main purpose is to attract more women
                            to start playing golf, develop their game and to
                            find their community to ensure they keep playing.
                            User acknowledges and agrees that it may only use
                            the Services in compliance with these Terms of Use.
                            By accepting these Terms of Use, by clicking on the
                            “I have read and accept the Terms and conditions”
                            button when signing up, you acknowledge and agree to
                            have read and be bound by these Terms of Use. If you
                            do not wish to be bound by these Terms of Use, you
                            may not use the Service.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            User responsibility
                        </span>
                        <p>
                            No devices or connections necessary for the use of
                            Service are provided subject to these Terms of Use.
                            You are responsible for purchasing, maintaining and
                            updating such devices and connections, including (1)
                            all equipment necessary for their own Internet
                            connection, handheld device, mobile phone, computer
                            and modem and (2) provision for User’s access to the
                            Internet, and (3) payment of any fees relate with
                            such connection.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Potential modifications of the Terms of Use
                        </span>
                        <p>
                            ARAY may modify the terms and conditions of these
                            Terms of Use, in whole or in part, at any time. User
                            shall be informed of any modification of these Terms
                            of Use via an appropriate method of information,
                            such as e-mail, and shall be deemed to have accepted
                            any of these modifications upon thirty (30) days of
                            User’s continued access or use of the Service as
                            from the date of ARAY’s notification of the
                            modification, without having terminated such Terms
                            of Use in the conditions set out in article 8 below.
                            These Terms of Use, as modified, shall then be
                            applicable to the access and use of the Service by
                            User. If User does not wish to be bound by any
                            modification of these Terms of Use, User undertakes
                            to immediately stop accessing and using the Service.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            ARAY’s Intellectual Property Rights
                        </span>
                        <p>
                            All copyrights in the Services are owned by ARAY or
                            its licensors to the fullest extent provided under
                            the applicable laws. All rights in the company
                            names, trademarks, trade names, logos and designs
                            whether or not appearing in large print or with the
                            trademark symbol, belong exclusively to ARAY or its
                            licensors, and are protected from reproduction,
                            imitation, dilution or confusing or misleading uses
                            under national and international trademark laws. The
                            use or misuse of any copyrights or trademarks is
                            prohibited and nothing stated or implied on the
                            Service confers on you any licence or right under
                            any copyright or trademark of ARAY or any third
                            party.
                        </p>
                        <p>
                            The Service and the Materials are intended for
                            customers of ARAY. You may not use the Service or
                            the Materials for any purpose not related to your
                            business with ARAY. Any use of the Service or the
                            Materials or any of its functionality for a purpose
                            not permitted by this Agreement is grounds for the
                            immediate revocation of any usernames, passwords or
                            other permissions that may have been granted to you
                            by ARAY for use of the Service.
                        </p>
                        <p>
                            Except as specifically allowed in these Terms of
                            Use, User is not entitled to use, copy, reproduce,
                            republish, store, modify, transfer, display, encode,
                            transmit, distribute, lease, licence, sell, rent,
                            lend, convey, upload or otherwise transfer, assign
                            or make publicly available your account, the
                            Service, a part thereof or the material contained
                            therein in any way. User is not entitled to adapt,
                            translate, reverse engineer, decompile, disassemble
                            or attempt to discover the source code, underlying
                            ideas, algorithms, methods, techniques, file formats
                            or programming interfaces of, or create derivative
                            works from the Service or any part thereof, except
                            to the extent permitted under the applicable laws.
                            User is not entitled to remove, modify, hide,
                            obscure, disable or modify any copyright, trademark
                            or other proprietary rights notices, marks, labels
                            or any other branding elements contained on or
                            within the Service, falsify or delete any author
                            attributions, legal notices or other labels of the
                            origin or source of the material, or misrepresent
                            the source of ownership of the Service.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Data protection
                        </span>
                        <p>
                            User acknowledges and agrees that it has read and
                            accepts the Privacy Policy available at
                            https://www.araysociety.com/privacy/ that is
                            incorporated herein by reference and that forms part
                            of these Terms of Use.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            User comments, feedback and other submissions
                        </span>
                        <p>
                            You do not have to submit anything to us, but if you
                            choose to submit something (including any User
                            generated content, feedback, ideas, concepts,
                            techniques and data), you grant, by concluding this
                            Agreement, a nonexclusive, irrevocable, worldwide,
                            perpetual, unlimited, assignable, sublicensable,
                            fully paid up and royalty free right to us to copy,
                            modify, further develop, prepare derivative works
                            of, improve, distribute, publish, display, remove,
                            retain, add, and use, distribute, sell, sublicense,
                            commercialise and otherwise exploit, in any way now
                            known or in the future discovered, anything that you
                            submit to us, without any further consent, notice
                            and/or compensation to you or to any third parties.
                            By submitting any information to us, you represent
                            and warrant that such submission is accurate, is not
                            confidential, and is not in violation of any
                            contractual restrictions or other third-party
                            rights.
                        </p>
                        <p>
                            ARAY exercises no control over any content you or
                            others submit while using the Service. ARAY has no
                            obligation to verify the identity of any Users when
                            they are connected to the site or to supervise the
                            content which has been provided by Users. Note that
                            we describe our current practices related to
                            personally identifiable information collected
                            through the Service in our Privacy Policy and that
                            we may update our policies and practices from time
                            to time at our sole discretion. Should you believe
                            that someone is misusing or otherwise appropriating
                            your information, you must immediately inform ARAY.
                        </p>
                        <p>
                            Prior to joining the Service, you must consider and
                            decide, yourself, the extent to which you wish to
                            reveal information about yourself to the large
                            community of the Service Users and to ARAY and you
                            must not communicate to the Service, its Users and
                            ARAY any information the dissemination of which
                            could be harmful to you.
                        </p>
                        <p>
                            We may, but have no obligation to, monitor, edit or
                            remove content and comments that we determine in our
                            sole discretion are unlawful, offensive,
                            threatening, libellous, defamatory, pornographic,
                            obscene or otherwise objectionable or violates any
                            party’s intellectual property or these Terms of Use.
                        </p>
                        <p>
                            By using ARAY you agree to not make comments that
                            could be libellous or considered offensive, and you
                            also agree to not misrepresent yourself, mislead
                            ARAY or other users. You agree to take
                            responsibility for the accuracy of any comments that
                            you make. We will monitor comments and remove any
                            that we believe may be offensive or objectionable.
                        </p>
                        <p>
                            You agree that your comments, input, messages or
                            other actions related to the Service will not
                            violate any right of ARAY or any third-party,
                            including copyright, trademark, privacy or other
                            personal or intellectual proprietary right. You
                            further agree that your comments will not contain
                            libellous or otherwise unlawful, abusive or obscene
                            material, or contain any computer virus or other
                            malware that could in any way affect the operation
                            of the Service or any related website. You may not
                            use a false e-mail address, pretend to be someone
                            other than yourself, or otherwise mislead us or
                            third-parties as to the origin of any comments. You
                            are solely responsible for any comments you make and
                            their accuracy. We take no responsibility and assume
                            no liability for any comments posted by you or any
                            third-party.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Warranties, Liabilities & limitations
                        </span>
                        <p>
                            ARAY warrants that it has the power and authority to
                            enter into these Terms of Use.The Service is made
                            available on an “as is” basis. ARAY does not warrant
                            that it will operate error-free or in an
                            uninterrupted manner or that the Service shall meet
                            User’s requirements or expectations or that they
                            will fit any particular purpose. User warrants that
                            it has the power and authority to enter into these
                            Terms of Use.
                        </p>
                        <p>
                            ARAY makes no representations or warranties of any
                            kind, expressed or implied, regarding the Service.
                            ARAY disclaims to the fullest extent by law, any and
                            all such representations and warranties without
                            limiting the generality of the foregoing, ARAY
                            disclaims to the maximum extent permitted by law any
                            and all warranties of merchantability or fitness for
                            a particular purpose; (I) warranties of
                            merchantability or fitness for a particular
                            purpose:; (II) warranties against infringement of
                            any third party intellectual property rights; (III)
                            warranties relating to delays, interruptions, errors
                            or omissions in the Service; (IV) warranties
                            relating to the transmission or delivery of the
                            service; (V) warranties relating to inaccuracies,
                            errors, emissions or the correctness of information;
                            and (VI) warranties otherwise relating to
                            performance, nonperformance or other acts or
                            Omissions by ARAY or any third party. Any
                            information on the service is subject to change
                            without notice, and we disclaim all responsibility
                            for these changes.
                        </p>
                        <p>
                            In no event will ARAY or our commonly owned or
                            controlled affiliates or any party involved in
                            creating, producing or delivering information, the
                            service or any website linked to the service be
                            liable to you or any other person or entity in any
                            manner whatsoever for direct, indirect, special,
                            consequential or incidental damages or any other
                            damages, liabilities, costs, losses or expenses
                            arising out if this agreement, arising out of
                            axxess, use or inability to access or use the
                            Service, information or any website linked or by the
                            service or in connection with any failure of
                            performance, error, omission, interruption, defect,
                            delay in operation or transmission, computer virus,
                            or other system failure even if ARAY has been
                            advised of the possibility of such damages or
                            losses. These limitations apply whether the alleged
                            liability is based on contract, tort, negligence,
                            strict liability or any other basis. Because some
                            jurisdictions do not allow the exclusion or
                            limitation of incidental or consequential damages,
                            ARAY’s liability in such jurisdictions shall be
                            limited to the fullest extent permitted by law.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Indemnification
                        </span>
                        <p>
                            Please note that your User Account can be cancelled
                            by you or us at any time. If your User Account is
                            terminated, you agree that ARAY shall have no
                            obligations or liabilities towards you.
                        </p>
                        <p>
                            The User and ARAY may terminate the account at any
                            time, with or without cause. You understand and
                            agree that the cancellation of your User Account is
                            your sole right and remedy with respect to any
                            dispute with ARAY. If you or ARAY terminate your
                            account, or if ARAY suspends your access to the
                            Services. The obligations and liabilities of the
                            parties incurred prior to the termination date shall
                            survive the termination of this agreement for all
                            purposes.
                        </p>
                        <p>
                            We reserve our right to cancel an order or
                            participation if you breach or violate any of the
                            Terms related to the Services. In the event that we
                            make a change to, or cancel an account, we may
                            attempt to notify you by contacting the e-mail
                            address you provided at the time the account was
                            created. We reserve the right to limit or prohibit
                            orders that, in our sole judgement, appear to be
                            placed by dealers, resellers or distributors.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">Miscellaneous</span>
                        <p>
                            You agree that no joint venture, partnership,
                            employment or agency relationship exists between you
                            and ARAY as a result of this Agreement or your use
                            of the Service. Nothing contained in this Agreement
                            is in derogation of ARAY’s right to comply with
                            governmental, court and law enforcement requests or
                            requirements relating to your use of the Service or
                            information provided to or gathered by ARAY with
                            respect to such use. A printed version of this
                            Agreement and of any notice given in electronic form
                            shall be admissible in judicial or administrative
                            proceedings based upon or relating to this Agreement
                            to the same extent and subject to the same
                            conditions as other business documents and records
                            originally generated and maintained in printed form.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Modification of this agreement
                        </span>
                        <p>
                            ARAY reserves the right, at any time, to modify,
                            alter, or update the terms and conditions of this
                            Agreement without prior notice. Modifications shall
                            become effective immediately upon being posted at
                            the Service. You are bound by any such modifications
                            and should therefore periodically visit this page to
                            review the then-current Service Terms of Use to
                            which you are bound. Your continued use of the
                            Service or any webpage or feature available through
                            Service after modifications are posted constitutes
                            an acknowledgement and acceptance of the Agreement
                            and its modifications. If, at any time, you do not
                            wish to accept this Agreement, you may not use the
                            Service.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Jurisdiction in Sweden
                        </span>
                        <p>
                            The Service is operated in Sweden. We do not
                            represent that content or information presented on
                            the Service are appropriate or available for use in
                            other jurisdictions. If you access the Service from
                            a jurisdiction other than Sweden, you agree that you
                            do so on your own initiative, and are responsible
                            for compliance with local laws, if and to the extent
                            local laws are applicable to your use of the
                            Service.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">
                            Choice of law and forum
                        </span>
                        <p>
                            This Agreement shall be governed by and construed in
                            accordance with the laws of Sweden. Any dispute,
                            controversy or claim arising between ARAY and the
                            user arising out of or relating to this Agreement or
                            the existence, validity, termination, interpretation
                            of any term hereof or either party’s performance
                            obligations hereunder shall be finally settled in
                            the District Court of the City of Stockholm, Sweden
                            as the first instance.
                        </p>
                    </li>
                    <li>
                        <span className="point-title bold">Contacts</span>
                        <p>
                            If you have any questions or concerns regarding this
                            Terms of Use, please reach out to our customer
                            support at info@araysociety.com.
                        </p>
                        <div className="contact-details">
                            <p className="bold">Contacts details:</p>
                            <p>ARAY Society AB</p>
                            <p>Reg.no 559327-8772</p>
                            <p>Tyska Skolgränd 4</p>
                            <p>111 31 Stockholm, Sweden</p>
                        </div>
                    </li>
                </ol>
            </div>
        </Layout>
    )
}

export default TermsCondition
